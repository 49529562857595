<template>
  <div>
     <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">

            <div id="fm0" class="fm" v-if="!showCanvas[0]">
              <!-- <img src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/230916/6464f9b0-90c7-d856-c71a-15ebebbcfd54.png"> -->
              <img src="@/assets/img/fm-image/1.png">
              <div class="info">
                <div class="infos">
                  <div class="user-info">
                      <div class="pointer">
                        <img class="pointer-img" src="@/assets/img/book-detail/pointer@3x.png"/>
                      </div>
                      <div class="user">
                        <div class="user-name">我是 {{ userName }}</div>
                        <div class="share-text">我是码上悦读读者<br>识别二维码,和我一起码上阅读</div>
                      </div>
                    </div>
                    <div class="qr-code">
                      <img class="qr-code-bg" src="@/assets/img/book-detail/qrcode@3x.png"/>
                      <img class="qr-code-img" :src="qrCode">
                    </div>
                </div>
              </div>
            </div>
            <div class="share-canvas" v-else>

              <img :src="canvasImageUrl[0]" class="share-canvas-img"/>
            </div>
          </div>
        <div  class="swiper-slide">

           <div id="fm1" class="fm" v-if="!showCanvas[1]">
              <!-- <img src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/230916/748e08ad-c3dd-c39c-3275-13938bf09433.png"/> -->
                <img src="@/assets/img/fm-image/2.png">
              <div class="info">
                <div class="infos">
                  <div class="user-info">
                      <div class="pointer">
                        <img class="pointer-img" src="@/assets/img/book-detail/pointer@3x.png"/>
                      </div>
                      <div class="user">
                        <div class="user-name">我是 {{ userName }}</div>
                        <div class="share-text">我是码上悦读读者<br>识别二维码,和我一起码上阅读</div>
                      </div>
                    </div>
                    <div class="qr-code">
                      <img class="qr-code-bg" src="@/assets/img/book-detail/qrcode@3x.png"/>
                      <img class="qr-code-img" :src="qrCode">
                    </div>
                </div>
              </div>
            </div>
            <div class="share-canvas" v-else>

              <img :src="canvasImageUrl[1]" class="share-canvas-img"/>
          </div>
         </div>
        <div class="swiper-slide">

            <div id="fm2" class="fm"  v-if="!showCanvas[2]">
             <!-- <img src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/230916/033f4081-2d16-23f9-dc4f-0aa061acfc08.png"/> -->
              <img src="@/assets/img/fm-image/3.png">
              <div class="info">
                <div class="infos">
                  <div class="user-info">
                      <div class="pointer">
                        <img class="pointer-img" src="@/assets/img/book-detail/pointer@3x.png"/>
                      </div>
                      <div class="user">
                        <div class="user-name">我是 {{ userName }}</div>
                        <div class="share-text">我是码上悦读读者<br>识别二维码,和我一起码上阅读</div>
                      </div>
                    </div>
                    <div class="qr-code">
                      <img class="qr-code-bg" src="@/assets/img/book-detail/qrcode@3x.png"/>
                      <img class="qr-code-img" :src="qrCode">
                    </div>
                </div>
              </div>
            </div>
            <div class="share-canvas" v-else>
                <img :src="canvasImageUrl[2]" class="share-canvas-img"/>
            </div>
          </div>
        <div class="swiper-slide">
            <div id="fm3" class="fm" v-if="!showCanvas[3]">
             <!-- <img src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/230916/f0e19d2e-7d63-e239-7e25-c39074c90632.png"/> -->
              <img src="@/assets/img/fm-image/4.png">
              <div class="info">
                <div class="infos">
                  <div class="user-info">
                      <div class="pointer">
                        <img class="pointer-img" src="@/assets/img/book-detail/pointer@3x.png"/>
                      </div>
                      <div class="user">
                        <div class="user-name">我是 {{ userName }}</div>
                        <div class="share-text">我是码上悦读读者<br>识别二维码,和我一起码上阅读</div>
                      </div>
                    </div>
                    <div class="qr-code">
                      <img class="qr-code-bg" src="@/assets/img/book-detail/qrcode@3x.png"/>
                      <img class="qr-code-img" :src="qrCode">
                    </div>
                </div>
              </div>
            </div>
             <div class="share-canvas" v-else>
                <img :src="canvasImageUrl[3]" class="share-canvas-img"/>
            </div>
          </div>
      </div>
      <div class="swiper-pagination"></div>

    </div>

  </div>

</template>

<script>
import Swiper from 'swiper'
import { mapGetters } from 'vuex'
import { getQrCode } from '@/api/Common'
import { LOCAL } from '@/utils/const'
import { Toast } from 'vant'
import html2canvas from 'html2canvas'
export default {

  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid',
      'userName'
    ])
  },
  data () {
    return {
      qrCode: '',
      canvasImageUrl: ['', '', '', ''],
      showCanvas: [false, false, false, false],
      realIndexs: 0,
      inits: true

    }
  },
  created () {

  },
  mounted () {
    this.inits = true
    this.createQrCode()
    this.inIt()
    this.$nextTick(() => {
      setTimeout(() => {
        if (!this.showCanvas[this.realIndexs]) { this.htmlToCanvas() }
      }, 500)
    })
  },
  methods: {
    inIt () {
      let athsi = this
      this.swiper = new Swiper('.swiper-container', {
        autoplay: false, // 是否自动播放 true 是  false 否
        slidesPerView: 2, // 一排展示几个
        effect: 'coverflow', // 轮播图的切换效果  coverflow 3D
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper

        spaceBetween: '40%', // 每个轮播图之间设置距离（单位px）。
        loop: false, // 是否循环播放
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        coverflowEffect: { // cover flow是类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式。coverflow效果参数，可选值：
          rotate: 0, // slide做3d旋转时Y轴的旋转角度
          stretch: 10, // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
          depth: 1000, // slide的位置深度。值越大z轴距离越远，看起来越小。
          modifier: 9, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显
          slideShadows: false// 是否开启slide阴影
        },
        on: {
          slideChangeTransitionEnd: function () {
            athsi.realIndexs = this.realIndex
          }
        }

      })
    },
    // 创建二维码
    async createQrCode () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let qrurl = `${LOCAL}/#/home?agency_guid=${this.agencyGuid}&guide=${this.userGuid}`
      await getQrCode({
        qr_str: qrurl
      }).then(res => {
        if (res.code === 200) {
          this.qrCode = res.data
          Toast.clear()
        } else {
          Toast.fail(res.data)
        }
      })
    },
    // 生成分享图片
    htmlToCanvas () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let athis = this
      html2canvas(document.getElementById('fm' + this.realIndexs), {
        backgroundColor: null,
        useCORS: true,
        tainttest: true,
        logging: true,
        scale: 4
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL('image/png', 1.0)
        athis.canvasImageUrl[athis.realIndexs] = imageUrl

        athis.$set(athis.showCanvas, this.realIndexs, true)
        Toast.clear()
      })
    }

  },
  watch: {
    realIndexs () {
      this.$nextTick(() => {
        if (!this.showCanvas[this.realIndexs]) { this.htmlToCanvas() }
      })
    }
  }

}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
  .swiper-container {
      width: 100%;
       height: 812px;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      transition: 300ms;
      transform: scale(0.8);
      img{
          width: 572px;
         height: 812px;
      }
      .fm{
        width: 572px;
        height: 812px;
        position relative;
      }
    }
    .swiper-slide-active,.swiper-slide-duplicate-active{
          transform: scale(1);
          z-index:999
    }
     .info{
        position: absolute;
        bottom : 30px;
        width 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .infos{
          width: 540px;
          height 165px;
          background: #FFFFFF;
          box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.02);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .user-info{
              display flex
              justify-content center
              align-items center
              width 370px
              height 180px
              .pointer{
                width 22px
                height 180px
                margin-left: 5px;
                .pointer-img{
                    width 22px
                    height 22px
                    margin-top 22px
                  }

              }
              .user{
                display flex
                flex-wrap wrap
                justify-content: flex-start;
                align-items center
                width 300px
                padding-left 20px
                height 180px

                .user-name{
                  width 310px
                  font-size 30px
                  font-weight bold
                  color rgba(51, 51, 51, 1)
                  text-align: start;
                  no-wrap()
                }
                .share-text{
                  width 330px
                  line-height 40px
                  font-size 22px
                  color rgba(51, 51, 51, 1)
                  text-align: start;

                  }
              }

            }

        }
        .qr-code{
          position relative
          width 200px
          height 150px
          margin-right:  5px;
          .qr-code-bg{
            position absolute
            top 0
            left 0
            width 100%
            height 100%
          }

          .qr-code-img{
              position absolute
              top 6px
              left 8px
              width 135px
              height 135px
            }

        }

      }

.share-canvas{
   width: 572px;
   height: 812px;
   z-index 9999;
  .share-canvas-img{
     width: 572px;
     height: 812px;
    }

}

</style>
