<template>
  <div @touchmove.prevent>
     <scroll
      ref="scroll"
      class="scroll"
      :listen-scroll="true"
      @scroll="scroll"
      :pull-down-refresh="{threshold: 30, stop: 0}"
      @pullingDown="pullDownRefresh"
      :pull-up-load="true"
      @pullingUp="pullUpPush"
    >
      <div>
           <back class="back" back-url="/"></back>
           <Cover-swiper></Cover-swiper>
          <div class="logs">
            <router-link to="/personal/my-promotionlog"> 推广记录</router-link>
            </div>
          <div class="buttonMain">
            <div class="button">长按保存图片</div>
          </div>
      </div>

     </scroll>
  </div>

</template>

<script>
import Scroll from '@/components/scroll/scroll'
import CoverSwiper from './components/Swiper'
import { mapGetters } from 'vuex'
import Back from '@/components/back/Back'
export default {
  name: 'promotion',
  components: {
    Scroll,
    CoverSwiper,
    Back
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  data () {
    return {
      isPullingDown: false,
      coverShow: false
    }
  },
  mounted () {

  },
  methods: {
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {

    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
    }

  }
}
</script>

<style lang='stylus' scoped>
.back{
  width 100%;
  height 107px;
}
.scroll
  position absolute
  top 0
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden
  background-color: #fff;
  .pull-down-wrapper
    display flex
    justify-content center
    align-items center
    width 750px
    height 70px
    margin-top -70px

    .pull-down-text
      line-height normal
      font-size 22px
      color rgba(153, 153, 153, 1)
  .logs{
    margin-top: 85px;
    width 100%;
    height 100px;
    text-align: center;
    text-decoration: underline;
   }
  .buttonMain{
    width 100%;
    height 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    .button{
      width: 560px;
      height: 70px;
      background: #B3353A;
      border-radius: 35px;
      text-align: center;
      line-height: 70px;
    }
  }

</style>
