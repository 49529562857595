<template>
  <div class="header">
    <div class="back" @click="back">
      <div class="icon"></div>
      <div class="name">返回</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Back',
  props: {
    backUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    back () {
      let index = this.$route.path.lastIndexOf(this.backUrl)
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.header
  width 100%
  height 80px
  background rgba(255, 255, 255, 1)

  .back
    display flex
    align-items center
    width 159px
    height 80px

    .icon
      width 16px
      height 28px
      margin-left 43px
      bg-image('~@/assets/img/reading/back')
      background-size 100% 100%
      background-repeat no-repeat

    .name
      width 100px
      line-height normal
      font-size 26px
      font-weight 500
      color rgba(51, 51, 51, 1)
      margin-left 10px
</style>
